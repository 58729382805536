import { Close } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { User } from 'oidc-client-ts';
import BetterLessonLabLogo from '../../../../images/logos/betterlesson-lab-logo.svg';
import { getColor } from '../../constants/colors';
import { NAV_NAMES } from '../../constants/nav-names';
import { useFeatureFlagContext } from '../../context/FeatureFlagContext';
import { NAV_LINKS } from '../../nav-links';
import { ItemLinkType, NavLinkType } from '../../nav-links/types';
import styles from '../../styles.module.css';

type Props = {
  initials: string;
  logoLink: string;
  user: User | null | undefined;
  hasPerms: (link: NavLinkType | ItemLinkType) => boolean;
  handleSignout: () => void;
  toggleDrawer: () => void;
};

export const MobileNav = ({
  initials,
  logoLink,
  user,
  hasPerms,
  handleSignout,
  toggleDrawer,
}: Props): JSX.Element => {
  const { hasFeatureFlag } = useFeatureFlagContext();

  const renderCloseButton = () => (
    <Button onClick={toggleDrawer} variant="text">
      <Close style={{ fill: 'var(--color-grey-2)' }} />
    </Button>
  );

  const renderLogo = () => (
    <Stack
      display="flex"
      direction="row"
      marginBottom=".5rem"
      marginTop=".5rem"
      padding={1}
      justifyContent="space-between"
      width="100%"
    >
      <a href={logoLink}>
        <Avatar
          alt="BetterLesson"
          src={BetterLessonLabLogo}
          style={{ height: 'auto', width: '50%' }}
          variant="square"
        />
      </a>
      {renderCloseButton()}
    </Stack>
  );

  const renderAccordionDetails = (menuItems: ItemLinkType[]) => (
    <AccordionDetails>
      <List className={styles.menuList}>
        {menuItems.filter(hasPerms).map((item) => (
          <ListItem key={item.name}>
            <Button
              className={styles.menuLink}
              href={item.href}
              style={{ fontWeight: 'normal', color: 'var(--color-grey-2)' }}
              target={item.target ? item.target : '_self'}
              onClick={item.name === 'Log Out' ? handleSignout : undefined}
            >
              {item.name}
            </Button>
          </ListItem>
        ))}
      </List>
    </AccordionDetails>
  );

  const renderAccordion = (link: NavLinkType) => (
    <Accordion key={link.menuName} data-testid="menu-accordion">
      <AccordionSummary>
        <Typography className={styles.menuLink} fontWeight="bold">
          {link.menuName}
        </Typography>
      </AccordionSummary>

      {renderAccordionDetails(link.menuItems)}
    </Accordion>
  );

  const renderUserAccordion = (menuName: string, menuItems: ItemLinkType[]) => (
    <Accordion defaultExpanded key={menuName}>
      <AccordionSummary>
        <Stack direction="row">
          <Avatar alt="User" style={{ backgroundColor: getColor(initials) }}>
            {initials}
          </Avatar>

          <Typography fontWeight="700" marginLeft="1rem">
            {user?.profile.given_name} {user?.profile.family_name}
          </Typography>
        </Stack>
      </AccordionSummary>

      {renderAccordionDetails(menuItems)}
    </Accordion>
  );

  return (
    <Stack data-testid="mobile-nav">
      {renderLogo()}

      {NAV_LINKS.map((link: NavLinkType) => {
        if (link.menuName === NAV_NAMES.USER)
          return renderUserAccordion(link.menuName, link.menuItems);

        const ignoreList: string[] = [
          NAV_NAMES.SEARCH,
          NAV_NAMES.LOGO,
          NAV_NAMES.USER,
          NAV_NAMES.BL_TOOLS,
        ];

        if (
          hasPerms(link) &&
          hasFeatureFlag(link.menuName) &&
          !ignoreList.includes(link.menuName)
        )
          return renderAccordion(link);

        return null;
      })}
    </Stack>
  );
};
