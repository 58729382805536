import { TABS } from '../constants/group-perms';
import { NAV_NAMES } from '../constants/nav-names';
import { NavLinkType } from './types';

const access = TABS.COACHES;

export const COACHES: NavLinkType = {
  menuName: NAV_NAMES.COACHES,
  access,
  menuItems: [
    {
      name: NAV_NAMES.COACH_DASHBOARD,
      href: `${process.env.REACT_APP_LAB_URL}/lab/coach/home`,
      access,
    },
    {
      name: NAV_NAMES.SURVEYS,
      href: `${process.env.REACT_APP_SURVEY_WEB_URL}/coach`,
      access,
    },
  ],
};
