const COLORS = [
  '#004493',
  '#1A7CD9',
  '#2196F3',
  '#0B8484',
  '#0AABB1',
  '#53E6EB',
  '#95FFEE',
  '#107D54',
  '#33B55F',
  '#2ACAA1',
  '#25E8C8',
  '#FF6B2A',
  '#F4AB2A',
];

export const getColor = (initials: string): string => {
  const index =
    initials
      .split('')
      .map((letter) => letter.charCodeAt(0))
      .reduce((code, acc) => code + acc, 0) % COLORS.length;

  return COLORS[index];
};
