import { NAV_NAMES } from '../constants/nav-names';
import { NavLinkType } from './types';

export const RESOURCES: NavLinkType = {
  menuName: NAV_NAMES.RESOURCES,
  menuItems: [
    {
      name: NAV_NAMES.INSTRUCTIONAL_STRATEGIES,
      href: `${process.env.REACT_APP_TEACHING_URL}/search?types=strategy&from=instructional_strategies_browse`,
    },
    {
      name: NAV_NAMES.LESSON_PLANS,
      href: `${process.env.REACT_APP_TEACHING_URL}/browse/master_teachers/projects`,
    },
    {
      name: NAV_NAMES.STUDENT_CENTERED_TEACHING,
      href: `${process.env.REACT_APP_MARKETING_URL}/learning-experiences/student-centered-teaching?from=header_link`,
      access: { isLeader: false, isParticipant: false, matchAll: true },
      target: '_blank',
    },
    {
      name: NAV_NAMES.RESOURCE_CENTER,
      href: `${process.env.REACT_APP_MARKETING_URL}/resources/`,
      target: '_blank',
    },
  ],
};
