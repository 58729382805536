import { BL_CONNECT } from './bl-connect';
import { BL_TOOLS } from './bl-tools';
import { COACHES } from './coaches';
import { LAB } from './lab';
import { LEADER_HUB } from './leader-hub';
import { LOGO } from './logo';
import { RESOURCES } from './resources';
import { SEARCH } from './search';
import { USER } from './user';

export const NAV_LINKS = [
  LOGO,
  BL_TOOLS,
  COACHES,
  LEADER_HUB,
  LAB,
  BL_CONNECT,
  RESOURCES,
  SEARCH,
  USER,
];
