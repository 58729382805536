import { TABS } from '../constants/group-perms';
import { NAV_NAMES } from '../constants/nav-names';
import { NavLinkType } from './types';

const access = TABS.LEADER_HUB;

export const LEADER_HUB: NavLinkType = {
  menuName: NAV_NAMES.LEADER_HUB,
  access,
  menuItems: [
    {
      name: NAV_NAMES.REPORTS,
      href: `${process.env.REACT_APP_REPORTS_URL}`,
      access,
    },
    {
      name: NAV_NAMES.EDUCATORS,
      href: `${process.env.REACT_APP_REPORTS_URL}/educators`,
      access,
    },
    {
      name: NAV_NAMES.LEARNING_WALKS,
      href: `${process.env.REACT_APP_LEARNING_WALK_URL}/partner`,
      access,
    },
    {
      name: NAV_NAMES.COHORTS_EVENTS,
      href: `${process.env.REACT_APP_LAB_URL}/lab/leader/home`,
      access,
    },
    {
      name: NAV_NAMES.LEARNING_CATALOG,
      href: `${process.env.REACT_APP_MARKETING_URL}/catalog`,
      access,
    },
  ],
};
