import { NAV_NAMES } from './nav-names';

export const FEATURE_FLAG_CONSTANTS = Object.freeze({
  BL_TOOLS: NAV_NAMES.BL_TOOLS,
  COACHES: NAV_NAMES.COACHES,
  LEADER_HUB: NAV_NAMES.LEADER_HUB,
  LAB: NAV_NAMES.LAB,
  BL_CONNECT: NAV_NAMES.BL_CONNECT,
  RESOURCES: NAV_NAMES.RESOURCES,
  USER: NAV_NAMES.USER,
  LOGO: NAV_NAMES.LOGO,
  SEARCH: NAV_NAMES.SEARCH,
  LAB_LOGO_PARTNER_LINK_ROUTES_TO_REPORTING:
    'bl-logo-routes-to-reporting-for-partners',
});
