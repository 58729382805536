import { TABS } from '../constants/group-perms';
import { NAV_NAMES } from '../constants/nav-names';
import { NavLinkType } from './types';

const access = TABS.BL_CONNECT;

export const BL_CONNECT: NavLinkType = {
  menuName: NAV_NAMES.BL_CONNECT,
  access: TABS.BL_CONNECT,
  menuItems: [
    {
      name: NAV_NAMES.BL_CONNECT_COURSES,
      href: `${process.env.REACT_APP_BL_CONNECT_URL}`,
      access,
    },
    {
      name: NAV_NAMES.COURSE_CATALOG,
      href: '//info.betterlesson.com/bl-connect/courses',
      access,
      target: '_blank',
    },
  ],
};
