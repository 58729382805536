export enum GROUP_NAMES {
  ADMIN = 'ADMIN',
  BL_CONTRACT_COACH = 'BL_CONTRACT_COACH',
  BL_MANAGER = 'BL_MANAGER',
  BL_SERVICES_MANAGER = 'BL_SERVICES_MANAGER',
  BL_TEAM_MEMBER = 'BL_TEAM_MEMBER',
  BL_USER = 'BL_USER',
  TC_CONTENT_CURATOR = 'TC_CONTENT_CURATOR',
  TC_DATA_VIEWER = 'TC_DATA_VIEWER',
  TC_PARTNER = 'TC_PARTNER',
  TC_TEACHER = 'TC_TEACHER',
}

export const TABS = {
  BL_TOOLS: { isSuperAdmin: true },
  COACHES: { isCoach: true },
  LEADER_HUB: { isLeader: true },
  LAB: { isFree: false },
  BL_CONNECT: { isFree: false },
};
