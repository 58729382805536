const color = 'var(--color-grey-2)';

export const ChildButtonStyle = {
  '&.MuiButtonBase-root:hover': {
    bgcolor: 'transparent',
  },
  color,
  paddingLeft: '1.5rem',
  paddingRight: '1.5rem',
  justifyContent: 'flex-start',
};

export const MenuButtonStyle = {
  borderRadius: 0,
  borderBottom: '0.125rem solid transparent',
  color,
  '&:hover': {
    backgroundColor: 'inherit',
    borderBottom: '.125rem solid var(--color-spring-green)',
  },
  fontFamily: 'Circular Std Bold',
};
