import { CircularProgress } from '@mui/material';
import { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { Outlet } from 'react-router-dom';
import { FEATURE_FLAG_CONSTANTS } from '../constants/FeatureFlags';
import { LaunchDarklyFactory } from '../utils/LaunchDarklyFactory';

type FeatureFlagContextType = {
  hasFeatureFlag: (featureFlag: string) => boolean;
};

export const FeatureFlagContext = createContext<FeatureFlagContextType>(
  {} as FeatureFlagContextType
);

export const FeatureFlagProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  const { user } = useAuth();
  const [isInitialized, setIsInitialized] = useState(true);
  const [featureFlags, setFeatureFlags] = useState<Record<string, boolean>>({});

  const getUserFeatureFlags = async () => {
    return {
      [FEATURE_FLAG_CONSTANTS.BL_TOOLS]:
        await LaunchDarklyFactory.displayUnifiedNavBlTools(user?.profile),
      [FEATURE_FLAG_CONSTANTS.COACHES]:
        await LaunchDarklyFactory.displayUnifiedNavCoaches(user?.profile),
      [FEATURE_FLAG_CONSTANTS.LEADER_HUB]:
        await LaunchDarklyFactory.displayUnifiedNavLeaderHub(user?.profile),
      [FEATURE_FLAG_CONSTANTS.LAB]:
        await LaunchDarklyFactory.displayUnifiedNavLab(user?.profile),
      [FEATURE_FLAG_CONSTANTS.BL_CONNECT]:
        await LaunchDarklyFactory.displayUnifiedNavBlConnect(user?.profile),
      [FEATURE_FLAG_CONSTANTS.RESOURCES]:
        await LaunchDarklyFactory.displayUnifiedNavResources(user?.profile),
      [FEATURE_FLAG_CONSTANTS.USER]:
        await LaunchDarklyFactory.displayUnifiedNavUser(user?.profile),
      [FEATURE_FLAG_CONSTANTS.LOGO]:
        await LaunchDarklyFactory.displayUnifiedNavLogo(user?.profile),
      [FEATURE_FLAG_CONSTANTS.SEARCH]:
        await LaunchDarklyFactory.displayUnifiedNavSearch(user?.profile),
      [FEATURE_FLAG_CONSTANTS.LAB_LOGO_PARTNER_LINK_ROUTES_TO_REPORTING]:
        await LaunchDarklyFactory.labLogoRoutesToReportingForPartners(
          user?.profile
        ),
    };
  };

  const fetchFeatureFlags = async () => {
    const userFeatureFlags = await getUserFeatureFlags();
    setFeatureFlags(userFeatureFlags);
    setIsInitialized(true);
  };

  useEffect(() => {
    fetchFeatureFlags();
  }, []);

  const hasFeatureFlag = (featureFlag: string) => !!featureFlags[featureFlag];

  return (
    <>
      {!isInitialized ? (
        <CircularProgress />
      ) : (
        <FeatureFlagContext.Provider value={{ hasFeatureFlag }}>
          <Outlet />
          {children}
        </FeatureFlagContext.Provider>
      )}
    </>
  );
};

export const useFeatureFlagContext = (): FeatureFlagContextType =>
  useContext(FeatureFlagContext);
