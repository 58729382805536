import { UnifiedHeaderWithFlags } from './UnifiedHeaderWithFlags';
import { FeatureFlagProvider } from './context/FeatureFlagContext';

type Props = {
  roles: string[];
};

export const UnifiedHeader = ({ roles }: Props): JSX.Element => {
  return (
    <FeatureFlagProvider>
      <UnifiedHeaderWithFlags roles={roles} />
    </FeatureFlagProvider>
  );
};
