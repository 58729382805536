import { TABS } from '../constants/group-perms';
import { NAV_NAMES } from '../constants/nav-names';
import { NavLinkType } from './types';

const access = TABS.BL_TOOLS;

export const BL_TOOLS: NavLinkType = {
  menuName: NAV_NAMES.BL_TOOLS,
  access,
  menuItems: [
    {
      name: NAV_NAMES.ADMIN_MENU,
      href: `${process.env.REACT_APP_LAB_URL}/bl/admin/home`,
      access,
    },
    {
      name: NAV_NAMES.EVENT_CAPACITY_CALENDAR,
      href: `${process.env.REACT_APP_LAB_URL}/bl/events/capacity-calendar`,
      access,
    },
    {
      name: NAV_NAMES.USER_SERVICE,
      href: `${process.env.REACT_APP_USER_MANAGEMENT_URL}`,
      access,
    },
    {
      name: NAV_NAMES.USER_MANAGEMENT,
      href: `${process.env.REACT_APP_LAB_URL}/bl/users`,
      access,
    },
    {
      name: NAV_NAMES.COHORT_MANAGEMENT,
      href: `${process.env.REACT_APP_LAB_URL}/bl/cohorts`,
      access,
    },
    {
      name: NAV_NAMES.EVENT_MANAGEMENT,
      href: `${process.env.REACT_APP_LAB_URL}/bl/events`,
      access,
    },
    {
      name: NAV_NAMES.PARTNER_MANAGEMENT,
      href: `${process.env.REACT_APP_LAB_URL}/bl/partners`,
      access,
    },
    {
      name: NAV_NAMES.LEARNING_DOMAIN_CURATION,
      href: `${process.env.REACT_APP_LAB_URL}/bl/curation/domains`,
      access,
    },
    {
      name: NAV_NAMES.STRATEGIES_TECH_TOOLS_CURATION,
      href: `${process.env.REACT_APP_LAB_URL}/bl/curation/strategies`,
      access,
    },
    {
      name: NAV_NAMES.COACH_BASE,
      href: '//betterlesson.quickbase.com/db/bmvddf9df',
      access,
      target: '_blank',
    },
  ],
};
