export type TitleLink = {
  title: string;
  href: string;
};

export type FooterLink = {
  header: TitleLink;
  children: TitleLink[];
};

const footerLinks: FooterLink[] = [
  {
    header: {
      title: 'Professional Learning',
      href: `${process.env.REACT_APP_MARKETING_URL}/professional-development`,
    },
    children: [
      {
        title: 'Learning Catalog',
        href: `${process.env.REACT_APP_MARKETING_URL}/catalog`,
      },
    ],
  },

  {
    header: {
      title: 'Resources',
      href: `${process.env.REACT_APP_MARKETING_URL}/resources`,
    },
    children: [
      {
        title: 'Instructional Strategies',
        href: `${process.env.REACT_APP_TEACHING_URL}/search?types=strategy&from=instructional_strategies_browse`,
      },
      {
        title: 'Lesson Plans',
        href: `${process.env.REACT_APP_TEACHING_URL}/browse/master_teachers/projects`,
      },
      {
        title: 'Resource Center',
        href: `${process.env.REACT_APP_MARKETING_URL}/resources`,
      },
    ],
  },

  {
    header: {
      title: 'Support & Contact',
      href: '//betterlesson.scrollhelp.site',
    },
    children: [
      {
        title: 'Help Center',
        href: '//betterlesson.atlassian.net/servicedesk/customer/portal/2/group/4',
      },
      {
        title: 'Check Network',
        href: `${process.env.REACT_APP_LAB_URL}/networkcheck`,
      },
      {
        title: 'Login to the BL Lab',
        href: `${process.env.REACT_APP_LAB_URL}/login`,
      },
      {
        title: 'Contact Us',
        href: '//info.betterlesson.com/contact-us',
      },
      {
        title: 'Privacy',
        href: `${process.env.REACT_APP_MARKETING_URL}/privacy-policy`,
      },
      {
        title: 'Terms and Conditions',
        href: `${process.env.REACT_APP_MARKETING_URL}/terms`,
      },
    ],
  },
];

export default footerLinks;
