export enum NAV_NAMES {
  ADMIN_MENU = 'Admin Menu',
  BL_CONNECT = 'BL Connect',
  BL_CONNECT_COURSES = 'BL Connect Courses',
  BL_LAB = 'BL Lab',
  BL_TOOLS = 'BL Tools',
  COACH_BASE = 'CoachBase',
  COACH_DASHBOARD = 'Coach Dashboard',
  COACHES = 'Coaches',
  COHORT_MANAGEMENT = 'Cohort Management',
  COHORTS_EVENTS = 'Cohorts & Events',
  COURSE_CATALOG = 'Course Catalog',
  EDUCATORS = 'Educators',
  EVENT_CAPACITY_CALENDAR = 'Event Capacity Calendar',
  EVENT_MANAGEMENT = 'Event Management',
  FAVORITES = 'Favorites',
  HELP_SUPPORT = 'Help & Support',
  INSTRUCTIONAL_STRATEGIES = 'Instructional Strategies',
  LAB = 'Lab',
  LEADER_HUB = 'Leader Hub',
  LEARNING_CATALOG = 'Learning Catalog',
  LEARNING_DOMAIN_CURATION = 'Learning Domain Curation',
  LEARNING_WALKS = 'Learning Walks',
  LESSON_PLANS = 'Lesson Plans',
  LOGO = 'Logo',
  LOG_OUT = 'Log Out',
  PD_WORKSPACE = 'PD Workspace',
  PARTNER_MANAGEMENT = 'Partner Management',
  REPORTS = 'Reports',
  RESOURCE_CENTER = 'Resource Center',
  RESOURCES = 'Resources',
  SEARCH = 'Strategies & Lessons',
  SETTINGS = 'Settings',
  STUDENT_CENTERED_TEACHING = 'Student-Centered Teaching and Learning',
  STRATEGIES_TECH_TOOLS_CURATION = 'Strategies & Tech Tools Curation',
  SURVEYS = 'Surveys',
  USER_MANAGEMENT = 'User Management',
  USER_SERVICE = 'User Service',
  USER = 'User',
}
