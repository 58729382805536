import * as LDClient from 'launchdarkly-js-client-sdk';
import { IdTokenClaims } from 'oidc-client-ts';
import { FEATURE_FLAG_CONSTANTS } from '../constants/FeatureFlags';

type FeatureFlag = {
  key: string;
  fallback: boolean;
};

export class LaunchDarklyFactory {
  private static instance: LDClient.LDClient | null;

  private static formatFeatureFlag = (featureFlag: string): string => {
    return featureFlag.replace(/&/g, '').replace(/\s+/g, '-').toLowerCase();
  };

  private static createUnifiedNavObject = (featureFlagConstant: string) => {
    return {
      key: `unified-nav-${LaunchDarklyFactory.formatFeatureFlag(
        featureFlagConstant
      )}`,
      fallback: false,
    };
  };

  private static BL_TOOLS = LaunchDarklyFactory.createUnifiedNavObject(
    FEATURE_FLAG_CONSTANTS.BL_TOOLS
  );

  private static COACHES = LaunchDarklyFactory.createUnifiedNavObject(
    FEATURE_FLAG_CONSTANTS.COACHES
  );

  private static LEADER_HUB = LaunchDarklyFactory.createUnifiedNavObject(
    FEATURE_FLAG_CONSTANTS.LEADER_HUB
  );

  private static LAB = LaunchDarklyFactory.createUnifiedNavObject(
    FEATURE_FLAG_CONSTANTS.LAB
  );

  private static BL_CONNECT = LaunchDarklyFactory.createUnifiedNavObject(
    FEATURE_FLAG_CONSTANTS.BL_CONNECT
  );

  private static RESOURCES = LaunchDarklyFactory.createUnifiedNavObject(
    FEATURE_FLAG_CONSTANTS.RESOURCES
  );

  private static USER = LaunchDarklyFactory.createUnifiedNavObject(
    FEATURE_FLAG_CONSTANTS.USER
  );

  private static LOGO = LaunchDarklyFactory.createUnifiedNavObject(
    FEATURE_FLAG_CONSTANTS.LOGO
  );

  private static SEARCH = LaunchDarklyFactory.createUnifiedNavObject(
    FEATURE_FLAG_CONSTANTS.SEARCH
  );

  private static LAB_LOGO_PARTNER_LINK_ROUTES_TO_REPORTING =
    LaunchDarklyFactory.createUnifiedNavObject(
      FEATURE_FLAG_CONSTANTS.LAB_LOGO_PARTNER_LINK_ROUTES_TO_REPORTING
    );

  private static buildLoggedInUserContextForLD = (context?: IdTokenClaims) => {
    const ldContext: LDClient.LDContext = {
      email: context?.email,
      key: context?.sub,
      firstName: context?.given_name,
      lastName: context?.family_name,
      kind: 'user',
    };
    return ldContext;
  };

  private static initializeLDClient = (
    profile?: IdTokenClaims
  ): LDClient.LDClient => {
    let context: LDClient.LDContext;
    if (profile !== null) {
      context = LaunchDarklyFactory.buildLoggedInUserContextForLD(profile);
    } else {
      context = {};
    }
    const clientId: string =
      process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID || '';
    return LDClient.initialize(clientId, context);
  };

  private static getFeatureFlag = async (
    flag: FeatureFlag,
    context?: IdTokenClaims
  ) => {
    if (!context) return flag.fallback;
    const client: LDClient.LDClient = LaunchDarklyFactory.getLDClient(context);
    try {
      await client.waitForInitialization();
      return client.variation(flag.key, flag.fallback) as boolean;
    } catch (err) {
      return flag.fallback;
    }
  };

  public static displayUnifiedNavBlTools = async (
    context?: IdTokenClaims
  ): Promise<boolean> => {
    return await LaunchDarklyFactory.getFeatureFlag(
      LaunchDarklyFactory.BL_TOOLS,
      context
    );
  };

  public static displayUnifiedNavCoaches = async (
    context?: IdTokenClaims
  ): Promise<boolean> => {
    return await LaunchDarklyFactory.getFeatureFlag(
      LaunchDarklyFactory.COACHES,
      context
    );
  };

  public static displayUnifiedNavLeaderHub = async (
    context?: IdTokenClaims
  ): Promise<boolean> => {
    return await LaunchDarklyFactory.getFeatureFlag(
      LaunchDarklyFactory.LEADER_HUB,
      context
    );
  };

  public static displayUnifiedNavLab = async (
    context?: IdTokenClaims
  ): Promise<boolean> => {
    return await LaunchDarklyFactory.getFeatureFlag(
      LaunchDarklyFactory.LAB,
      context
    );
  };

  public static displayUnifiedNavBlConnect = async (
    context?: IdTokenClaims
  ): Promise<boolean> => {
    return await LaunchDarklyFactory.getFeatureFlag(
      LaunchDarklyFactory.BL_CONNECT,
      context
    );
  };

  public static displayUnifiedNavResources = async (
    context?: IdTokenClaims
  ): Promise<boolean> => {
    return await LaunchDarklyFactory.getFeatureFlag(
      LaunchDarklyFactory.RESOURCES,
      context
    );
  };

  public static displayUnifiedNavUser = async (
    context?: IdTokenClaims
  ): Promise<boolean> => {
    return await LaunchDarklyFactory.getFeatureFlag(
      LaunchDarklyFactory.USER,
      context
    );
  };

  public static displayUnifiedNavLogo = async (
    context?: IdTokenClaims
  ): Promise<boolean> => {
    return await LaunchDarklyFactory.getFeatureFlag(
      LaunchDarklyFactory.LOGO,
      context
    );
  };

  public static displayUnifiedNavSearch = async (
    context?: IdTokenClaims
  ): Promise<boolean> => {
    return await LaunchDarklyFactory.getFeatureFlag(
      LaunchDarklyFactory.SEARCH,
      context
    );
  };

  public static labLogoRoutesToReportingForPartners = async (
    context?: IdTokenClaims
  ): Promise<boolean> => {
    return await LaunchDarklyFactory.getFeatureFlag(
      LaunchDarklyFactory.LAB_LOGO_PARTNER_LINK_ROUTES_TO_REPORTING,
      context
    );
  };

  private static getLDClient = (context?: IdTokenClaims) => {
    if (!this.instance) {
      this.instance = this.initializeLDClient(context);
    }
    return this.instance;
  };

  public static closeLDClient = async (): Promise<void> => {
    if (this.instance) {
      await this.instance.close();
      this.instance = null;
    }
  };
}
