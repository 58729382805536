import { Search } from '@mui/icons-material';
import { Avatar, Button, Menu, MenuItem, Paper, Stack } from '@mui/material';
import { useState, MouseEvent } from 'react';
import BetterLessonLabLogo from '../../../../images/logos/betterlesson-lab-logo.svg';
import { ChildButtonStyle, MenuButtonStyle } from '../../Styles';
import { getColor } from '../../constants/colors';
import { NAV_NAMES } from '../../constants/nav-names';
import { useFeatureFlagContext } from '../../context/FeatureFlagContext';
import { NAV_LINKS } from '../../nav-links';
import { ItemLinkType, NavLinkType } from '../../nav-links/types';
import styles from '../../styles.module.css';

type Props = {
  initials: string;
  logoLink: string;
  hasPerms: (link: NavLinkType | ItemLinkType) => boolean;
  handleSignout: () => void;
};

export const DesktopNav = ({
  initials,
  logoLink,
  hasPerms,
  handleSignout,
}: Props): JSX.Element => {
  const { hasFeatureFlag } = useFeatureFlagContext();
  const [anchorEl, setAnchorEl] = useState<Record<number, HTMLElement | null>>(
    {}
  );

  const handleClick = (e: MouseEvent<HTMLButtonElement>, i: number) => {
    setAnchorEl((prevAnchorEl) => ({
      ...prevAnchorEl,
      [i]: e.target as HTMLElement,
    }));
  };

  const handleClose = (i: number) => {
    setAnchorEl((prevAnchorEl) => ({
      ...prevAnchorEl,
      [i]: null,
    }));
  };

  const formatMenuName = (menuName: string) =>
    menuName.replace(/\s+/g, '-').toLowerCase();

  const safeListedMenuNames: string[] = [
    NAV_NAMES.USER,
    NAV_NAMES.SEARCH,
    NAV_NAMES.LOGO,
  ];

  return (
    <Stack
      data-testid="desktop-nav"
      display="flex"
      direction="row"
      width="100%"
    >
      {NAV_LINKS.map((link: NavLinkType, index: number) => {
        if (
          !hasPerms(link) ||
          (!hasFeatureFlag(link.menuName) &&
            !safeListedMenuNames.includes(link.menuName))
        )
          return null;

        return (
          <Paper elevation={0} key={link.menuName} className={styles.menuItem}>
            {!safeListedMenuNames.includes(link.menuName) ? (
              <>
                <Button
                  data-testid={`uninav-menu-${formatMenuName(link.menuName)}`}
                  disableRipple
                  sx={MenuButtonStyle}
                  onClick={(e) => handleClick(e, index)}
                >
                  {link.menuName}
                </Button>

                <Menu
                  anchorEl={anchorEl[index]}
                  className={styles.menu}
                  id="uninav-menu"
                  open={Boolean(anchorEl[index])}
                  onClose={() => handleClose(index)}
                  sx={{
                    marginTop: '.625rem',
                  }}
                  MenuListProps={{
                    'aria-labelledby': 'uninav-menu-button',
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  {link.menuItems.map(
                    (item) =>
                      hasPerms(item) && (
                        <MenuItem
                          data-testid={`uninav-menu-item-${formatMenuName(
                            item.name
                          )}`}
                          key={item.name}
                          style={{ padding: 0 }}
                          tabIndex={0}
                        >
                          <Button
                            disableRipple
                            fullWidth
                            href={item.href}
                            sx={ChildButtonStyle}
                            target={item.target ? item.target : '_self'}
                            variant="text"
                          >
                            {item.name}
                          </Button>
                        </MenuItem>
                      )
                  )}
                </Menu>
              </>
            ) : (
              <>
                {link.menuName === NAV_NAMES.LOGO && (
                  <a href={logoLink}>
                    <img
                      alt="BetterLesson"
                      data-testid={`uninav-menu-${formatMenuName(
                        link.menuName
                      )}`}
                      src={BetterLessonLabLogo}
                      style={{
                        height: '2.5rem',
                      }}
                    />
                  </a>
                )}

                {link.menuName === NAV_NAMES.SEARCH && (
                  <Button
                    data-testid={`uninav-menu-${formatMenuName(link.menuName)}`}
                    startIcon={<Search style={{ fontSize: '1.5rem' }} />}
                    variant="text"
                  >
                    <a
                      href="//teaching.betterlesson.com/search/1968156"
                      style={{
                        color: 'var(--color-vivid-blue)',
                        textDecoration: 'none',
                      }}
                    >
                      {link.menuName}
                    </a>
                  </Button>
                )}

                {link.menuName === NAV_NAMES.USER && (
                  <>
                    <Avatar
                      alt={NAV_NAMES.USER}
                      style={{ backgroundColor: getColor(initials) }}
                    >
                      <Button
                        color="inherit"
                        data-testid={`uninav-menu-${formatMenuName(
                          link.menuName
                        )}`}
                        disableRipple
                        onClick={(e) => handleClick(e, index)}
                      >
                        {initials}
                      </Button>

                      <Menu
                        anchorEl={anchorEl[index]}
                        className={styles.menu}
                        id="uninav-menu"
                        open={Boolean(anchorEl[index])}
                        onClose={() => handleClose(index)}
                        sx={{
                          marginTop: '.625rem',
                        }}
                        MenuListProps={{
                          'aria-labelledby': 'uninav-menu',
                        }}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        {link.menuItems.map(
                          (item) =>
                            hasPerms(item) && (
                              <MenuItem
                                data-testid={`uninav-menu-item-${formatMenuName(
                                  item.name
                                )}`}
                                key={item.name}
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                tabIndex={0}
                              >
                                {item.name === NAV_NAMES.LOG_OUT ? (
                                  <Button
                                    data-testid="uninav-log-out-btn"
                                    disableRipple
                                    fullWidth
                                    onClick={handleSignout}
                                    sx={ChildButtonStyle}
                                    variant="text"
                                  >
                                    {item.name}
                                  </Button>
                                ) : (
                                  <Button
                                    disableRipple
                                    fullWidth
                                    href={item.href}
                                    sx={ChildButtonStyle}
                                    variant="text"
                                  >
                                    {item.name}
                                  </Button>
                                )}
                              </MenuItem>
                            )
                        )}
                      </Menu>
                    </Avatar>
                  </>
                )}
              </>
            )}
          </Paper>
        );
      })}
    </Stack>
  );
};
