import { TABS } from '../constants/group-perms';
import { NAV_NAMES } from '../constants/nav-names';
import { NavLinkType } from './types';

const access = TABS.LAB;

export const LAB: NavLinkType = {
  menuName: NAV_NAMES.LAB,
  access,
  menuItems: [
    {
      name: NAV_NAMES.BL_LAB,
      href: `${process.env.REACT_APP_LAB_URL}/lab/home`,
      access,
    },
    {
      name: NAV_NAMES.PD_WORKSPACE,
      href: `${process.env.REACT_APP_LAB_URL}/lab/workspace`,
      access,
    },
  ],
};
