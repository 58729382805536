import { Menu as MenuIcon } from '@mui/icons-material';
import {
  AppBar,
  Avatar,
  Drawer,
  Button,
  Stack,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import BetterLessonLabLogo from '../../images/logos/betterlesson-lab-logo.svg';
import { DesktopNav } from './components/DesktopNav/DesktopNav';
import { MobileNav } from './components/MobileNav/MobileNav';
import { FEATURE_FLAG_CONSTANTS } from './constants/FeatureFlags';
import { GROUP_NAMES } from './constants/group-perms';
import { useFeatureFlagContext } from './context/FeatureFlagContext';
import { AccessType, ItemLinkType, NavLinkType } from './nav-links/types';
import { LaunchDarklyFactory } from './utils/LaunchDarklyFactory';

type Props = {
  roles: string[];
};

export const UnifiedHeaderWithFlags = ({ roles }: Props): JSX.Element => {
  const { hasFeatureFlag } = useFeatureFlagContext();
  const { user, signoutRedirect } = useAuth();
  const theme = useTheme();
  const isTabletOrLarger = useMediaQuery(theme.breakpoints.up('md'));
  const [mobileIsOpen, setMobileIsOpen] = useState(false);
  const [permissions, setPermissions] = useState<AccessType>({
    isSuperAdmin: false,
    isInteral: false,
    isFree: true,
    isLeader: false,
    isCoach: false,
    isParticipant: false,
  });

  const handleToggle = () => setMobileIsOpen(!mobileIsOpen);

  const handleSignout = () => {
    LaunchDarklyFactory.closeLDClient();
    signoutRedirect();
  };

  const objectsHaveMatchingProps = (
    perms: AccessType,
    linkAccess: AccessType
  ): boolean => {
    for (const key in linkAccess) {
      if (
        linkAccess.matchAll &&
        key !== 'matchAll' &&
        Object.prototype.hasOwnProperty.call(linkAccess, key)
      ) {
        if (
          linkAccess[key as keyof AccessType] !== perms[key as keyof AccessType]
        )
          return false;
      } else if (Object.prototype.hasOwnProperty.call(linkAccess, key)) {
        if (
          linkAccess[key as keyof AccessType] === perms[key as keyof AccessType]
        )
          return true;
      }
    }
    return linkAccess.matchAll ? true : false;
  };

  const hasPerms = (link: NavLinkType | ItemLinkType): boolean => {
    if (permissions.isSuperAdmin || permissions.isInteral || !link.access)
      return true;
    return objectsHaveMatchingProps(permissions, link.access);
  };

  const formatPermissions = () => {
    const internal = [
      GROUP_NAMES.BL_SERVICES_MANAGER,
      GROUP_NAMES.TC_CONTENT_CURATOR,
      GROUP_NAMES.BL_MANAGER,
      GROUP_NAMES.BL_TEAM_MEMBER,
      GROUP_NAMES.TC_DATA_VIEWER,
    ];

    const perms = {
      isSuperAdmin: roles.includes(GROUP_NAMES.ADMIN),
      isInteral:
        internal.some((perm) => roles.includes(perm)) &&
        !roles.includes(GROUP_NAMES.BL_CONTRACT_COACH),
      isFree:
        (roles.length === 1 &&
          (roles[0] === '' || roles[0] === GROUP_NAMES.BL_USER)) ||
        (roles.length === 2 &&
          roles.includes(GROUP_NAMES.BL_USER) &&
          roles.includes('')),
      isLeader: roles.includes(GROUP_NAMES.TC_PARTNER),
      isCoach:
        roles.includes(GROUP_NAMES.BL_CONTRACT_COACH) &&
        !roles.includes(GROUP_NAMES.BL_TEAM_MEMBER) &&
        !roles.includes(GROUP_NAMES.TC_DATA_VIEWER),
      isParticipant:
        roles.includes(GROUP_NAMES.TC_TEACHER) &&
        roles.includes(GROUP_NAMES.BL_USER),
    };

    setPermissions(perms);
  };

  const getInitials = (): string => {
    if (!user || !user.profile.given_name) return '';
    return (
      (
        user.profile.given_name?.charAt(0) + user.profile.family_name?.charAt(0)
      ).toUpperCase() || ''
    );
  };

  const getLogoLink = (): string => {
    switch (true) {
      case permissions.isSuperAdmin:
      case permissions.isInteral:
        return `${process.env.REACT_APP_USER_MANAGEMENT_URL}`;
      case permissions.isLeader:
        if (
          hasFeatureFlag(
            FEATURE_FLAG_CONSTANTS.LAB_LOGO_PARTNER_LINK_ROUTES_TO_REPORTING
          )
        ) {
          return `${process.env.REACT_APP_REPORTS_URL}`;
        }
        return `${process.env.REACT_APP_LAB_URL}/lab/home`;
      case permissions.isCoach:
        return `${process.env.REACT_APP_LAB_URL}/lab/coach/home`;
      case permissions.isParticipant:
        return `${process.env.REACT_APP_LAB_URL}/lab/home`;
      default:
        return `${process.env.REACT_APP_LAB_URL}/home`;
    }
  };

  useEffect(() => {
    if (!roles.length) return;
    formatPermissions();
  }, [roles]);

  return (
    <AppBar elevation={3} position="relative">
      <Toolbar>
        {!isTabletOrLarger ? (
          <>
            <Stack
              data-testid="mobile-nav-container"
              direction="row"
              justifyContent="space-between"
              width="100%"
            >
              <a href={getLogoLink()}>
                <Avatar
                  alt="BetterLesson"
                  src={BetterLessonLabLogo}
                  style={{ height: 'auto', width: '50%' }}
                  variant="square"
                />
              </a>

              <Button
                data-testid="btn-toggle"
                onClick={handleToggle}
                style={{ justifyContent: 'end' }}
              >
                <MenuIcon style={{ fill: 'var(--color-grey-2)' }} />
              </Button>
            </Stack>

            <Drawer
              anchor="top"
              open={mobileIsOpen}
              PaperProps={{
                style: {
                  width: '100%',
                },
              }}
            >
              <MobileNav
                hasPerms={hasPerms}
                initials={getInitials()}
                logoLink={getLogoLink()}
                handleSignout={handleSignout}
                toggleDrawer={handleToggle}
                user={user}
              />
            </Drawer>
          </>
        ) : (
          <DesktopNav
            hasPerms={hasPerms}
            initials={getInitials()}
            logoLink={getLogoLink()}
            handleSignout={handleSignout}
          />
        )}
      </Toolbar>
    </AppBar>
  );
};
